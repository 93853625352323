import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { injectIntl, FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import SEO from '../components/seo'
import { Link } from '../i18n'

const OuterContainer = styled.div`
  padding: 30px 0;
  max-width: 1000px;
  width: 100%;
`

const InnerContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: auto;
`

const Item = styled.div`
  flex: 0 0 320px;
  padding: 5px;
`

const Subgallery = styled.div`
  position: relative;
  color: white;

  & img {
    transform: scale(1);
    transition: opacity 0.5s ease 0s, transform 0.3s ease-in-out !important;
  }

  &:hover {
    color: ${props => props.theme.colors.orange};
    img {
      transform: scale(1.1);
    }
  }
`

const Info = styled.div`
  position: absolute;
  bottom: 0;
  height: 20%;
  width: 100%;
  z-index: 1;
  background: ${props => props.theme.colors.blackOverlay};
  display: flex;
  align-items: center;
`

const Caption = styled.p`
  font-size: 1.2rem;
  margin-bottom: 0;
  margin-left: 15px;
  text-transform: uppercase;
`

const SUBGALLERY_ITEM_WIDTH = 320

class GalleryPage extends Component {
  constructor(props) {
    super(props)
    this._outerContainer = React.createRef()
    this._innerContainer = React.createRef()
  }

  componentDidMount() {
    this._handleWindowResize()
    window.addEventListener('resize', this._handleWindowResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._handleWindowResize)
  }

  _handleWindowResize = () => {
    const containerWidth = this._outerContainer.current.offsetWidth
    const itemCount = ~~(containerWidth / SUBGALLERY_ITEM_WIDTH)

    this._innerContainer.current.style.width = `${itemCount *
      SUBGALLERY_ITEM_WIDTH}px`
  }

  render() {
    const { data, intl } = this.props
    const subgalleries = data.prismicGallery.data.subgalleries

    return (
      <>
        <SEO title={intl.formatMessage({ id: 'gallery' })} />
        <OuterContainer ref={this._outerContainer}>
          <InnerContainer ref={this._innerContainer}>
            {subgalleries.map(
              ({
                subgallery: {
                  document: [{ data, uid }],
                },
              }) => (
                <Item key={uid}>
                  <Link to={`/gallery/${uid}`}>
                    <Subgallery>
                      <Info>
                        <Caption>
                          {data.i18n_id ? (
                            <FormattedMessage id={data.i18n_id} />
                          ) : (
                            data.name
                          )}
                        </Caption>
                      </Info>
                      <Img
                        alt={data.thumbnail.alt}
                        fluid={data.thumbnail.localFile.childImageSharp.fluid}
                      />
                    </Subgallery>
                  </Link>
                </Item>
              )
            )}
          </InnerContainer>
        </OuterContainer>
      </>
    )
  }
}

export default injectIntl(GalleryPage)

export const query = graphql`
  {
    prismicGallery {
      data {
        subgalleries {
          subgallery {
            document {
              uid
              data {
                name
                thumbnail {
                  alt
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 310, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                i18n_id
              }
            }
          }
        }
      }
    }
  }
`
